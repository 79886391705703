import { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import { bookFromOpds } from "libs/files";
import Header from "components/Header";
import Footer from "components/Footer";
import Rarity from "components/Rarity";
import ProgressiveImage from "components/ProgressiveImage";
import fingerprint from "images/finger-print-icon.svg";

export default function Details() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [book, setBook] = useState<any>(null);
  const [identity, setIdentity] = useState<string>("");

  const truncateString = (str: string) => {
    var str = str.slice(0, 10) + "…" + str.substring(str.length - 10);
    return str;
  };

  useEffect(() => {
    async function getBook() {
      const currentUser = await Auth.currentUserInfo();
      setIdentity(currentUser.attributes["custom:blockchain_address"]);
      const book = bookFromOpds(id!);
      setBook(book);
    }
    getBook();
  }, [id]);

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow bg-ct-lightgrey px-4">
        {book && (
          <section className="container my-12 max-w-5xl mx-auto grid grid-cols-1 gap-12 md:grid-cols-2">
            <div className="rounded-sm drop-shadow-lg shadow-ct-darkgrey overflow-hidden">
              <ProgressiveImage
                src={`https://s3.amazonaws.com/${process.env.REACT_APP_AWS_BUCKET as string}/protected/${identity}/${book.link[0].href}`}
                alt={book.title}
              />
            </div>
            <div>
              <h1 className="text-5xl text-bookchain-blue ">{book.title}</h1>
              <p className="text-ct-text font-light border-b border-ct-white mb-6 pb-6">
                {t("global.label.by")}{" "}
                {book.author
                  ? book.author
                      .map((item: any) => {
                        return item.name;
                      })
                      .join(", ")
                  : t("local.bookdetail.label.unknown")}
              </p>
              <div className="grid grid-cols-4 gap-4">
                <div className="bg-white drop-shadow-md rounded p-2">
                  <h3 className="text-sm text-ct-text text-center pb-2">{t("local.bookdetail.item.runsize")}</h3>
                  <div className="text-xl font-bold text-ct-text text-center">{book.nft.maximum}</div>
                </div>
                <div className="bg-white drop-shadow-md rounded p-2">
                  <h3 className="text-sm text-ct-text text-center pb-2">{t("local.bookdetail.item.tolkennum")}</h3>
                  <div className="text-xl font-bold text-bookchain-blue text-center">{book.nft.mint}</div>
                </div>
              </div>
              <div className="py-6 border-b border-ct-white">
                <h2 className="text-lg">
                  <img className="h-6 inline text-ct-text" src={fingerprint} /> {t("local.bookdetail.section.nftdetails")}
                </h2>
                <p>
                  <span className="text-sm text-ct-text ">{t("local.bookdetail.item.tokenid")}:</span>{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={process.env.REACT_APP_POLYGON_TOKEN + book.nft.contract + "?a=" + book.nft.id}
                    className="font-bold text-bookchain-blue"
                  >
                    #{book.nft.id}
                  </a>
                </p>
                <p>
                  <span className="text-sm text-ct-text ">{t("local.bookdetail.item.contractid")}:</span>{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={process.env.REACT_APP_POLYGON_TOKEN + book.nft.contract}
                    className="font-bold text-bookchain-blue"
                  >
                    {truncateString(book.nft.contract)}
                  </a>
                </p>
              </div>
              <h2 className="text-lg pt-6">{t("local.bookdetail.section.description")}</h2>
              <p
                className="text-base text-ct-text font-light pb-6"
                dangerouslySetInnerHTML={{ __html: decodeURI(book.content["#text"].replaceAll("&#xD;", "<br>")) }}
              ></p>
              <h2 className="text-lg">{t("local.bookdetail.section.additionaldetails")}</h2>
              <table className="table-auto">
                <tbody>
                  {book.contributor && (
                    <tr>
                      <th className="pr-6 text-left text-sm text-ct-text font-normal">{t("local.bookdetail.item.contributors")}:</th>
                      <td>
                        {book.contributor
                          .map((item: any) => {
                            return item.name;
                          })
                          .join(", ")}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th className="text-left text-sm text-ct-text font-normal">{t("local.bookdetail.item.language")}:</th>
                    <td className="uppercase">{book["dc:language"].substring(0, 2)}</td>
                  </tr>
                  <tr>
                    <th className="text-left text-sm text-ct-text font-normal">{t("local.bookdetail.item.isbn")}:</th>
                    <td>{book["dc:identifier"][0].split(":")[2]}</td>
                  </tr>
                  <tr>
                    <th className="text-left text-sm text-ct-text font-normal">{t("local.bookdetail.item.publisher")}:</th>
                    <td>{book["dc:publisher"]}</td>
                  </tr>
                </tbody>
              </table>
              <h2 className="text-xl pt-6">{t("global.label.navigation")}</h2>
              <Link to="/" className="block text-sm text-bookchain-blue hover:text-black mb-2">
                {t("global.link.returntolibrary.text")}
              </Link>
            </div>
          </section>
        )}
      </main>
      <Footer />
    </div>
  );
}
