//import Logo from "components/Logos";
//import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ConsentPopup from "./OptOut";

const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <footer className="w-full flex-shrink-0">
    <div className="menu bg-grey-100 clearfix">
        <nav className="container items-center justify-between mx-auto px-4 sm:flex sm:px-auto">
          <ul className="list-none text-center w-auto">
            <li className="py-4 sm:inline-block sm:mr-4 sm:py-8"><a className="text-grey-700 text-sm font-medium" href={t("global.link.about.url")}>{t("global.link.about.textlong")}</a></li>
            <li className="py-4 sm:inline-block sm:mr-4 sm:py-8"><a className="text-grey-700 text-sm font-medium" href={t("global.link.faq.url")}>{t("global.link.faq.textlong")}</a></li>
            <li className="inline-block pb-4 sm:py-8 mr-4"><a aria-label="Twitter" className="align-middle text-grey-700 text-3xl sm:text-lg trans hover:text-brand-twitter" href={t("global.link.twitter.url")}><i className="icon-social-twitter"></i></a></li>
            <li className="inline-block pb-4 sm:py-8 sm:mr-4"><a aria-label="Facebook" className="align-middle text-grey-700 text-3xl sm:text-lg trans hover:text-brand-facebook" href={t("global.link.facebook.url")}><i className="icon-social-facebook"></i></a></li>
          </ul>
        </nav>
      </div>
      <div className="legal bg-grey-300">
        <div className="container flex h-24 items-center mx-auto px-4 text-center text-grey-700 text-xs sm:px-auto sm:h-10">
          <span>&copy; 2015-{new Date().getFullYear()} {t("local.footer.copyright.company")} <a className="text-blue-500 lowercase font-bold" href={t("global.link.terms.url")}>{t("global.link.terms.text")}</a>, <a className="text-blue-500 lowercase font-bold" href={t("global.link.privacy.url")}>{t("global.link.privacy.text")}</a> &amp; <a className="text-blue-500 lowercase font-bold" href={t("global.link.dataprotection.url")}>{t("global.link.dataprotection.text")}</a>.</span>
        </div>
      </div>
      <ConsentPopup />
  </footer>
  );
};

export default Footer;
