import * as Sentry from "@sentry/browser";
import { useTranslation } from "react-i18next";
import { config } from "config";
import { error } from "console";

const isLocal = process.env.NODE_ENV === "development";

const errorCodes = [
  "UserNotConfirmedException",
  "NotAuthorizedException",
  "PasswordResetRequiredException",
  "CodeMismatchException",
  "ExpiredCodeException",
  "UsernameExistsException",
  "EnableSoftwareTokenMFAException",
  "InvalidParameterException",
  "UserLambdaValidationException",
  "NetworkError",
  "invalid_code",
  "locked_code",
  "undefined"
];

function ErrorMessage({ code, message }: { code: string; message: string }) {
  const { t } = useTranslation();
  let errorMessage = "";
  if (errorCodes.includes(code)) {
    errorMessage = t("global.error." + code);
  } else if (errorCodes.includes(message)) {
    errorMessage = t("global.error." + message);
  } else {
    errorMessage = code + ": " + message
  }
  return (
    <div className="bg-ct-pink text-ct-error text-base border border-ct-salmon py-2 px-5 mt-6">
      <p className="font-bold">{t("global.label.error")}</p>
      <p data-testid="error-message">{errorMessage}</p>
    </div>
  );
}

export function initSentry() {
  if (isLocal) {
    return;
  }

  Sentry.init({ dsn: config.SENTRY_DSN, environment: config.SENTRY_ENV });
}

export function logError(error: Error, errorInfo?: {}) {
  if (isLocal) {
    console.error(error, errorInfo);
    return;
  }

  Sentry.withScope((scope) => {
    errorInfo && scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });
}

export function onError(error: any) {
  let errorInfo = { url: "" };
  let gatewayError = error.response?.data.error;
  let message = error.toString();

  if (!(error instanceof Error) && error.message) {
    errorInfo = error;
    message = error.message;
    error = new Error(message);
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url;
  } else if (gatewayError) {
    error.code = gatewayError;
  }

  logError(error, errorInfo);

  return <ErrorMessage code={gatewayError || error.code} message={error.message} />;
}
