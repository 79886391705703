import { Auth } from "aws-amplify";
import { S3 } from "aws-sdk";
import { XMLParser } from "fast-xml-parser";
import { IContentLocation } from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-base";

type Library = {
  status: string;
  books: any;
};

type Settings = {
  location: IContentLocation | null;
  bookmarks: object | null;
  annotations: object | null;
}

async function initS3() {
  const token = await Auth.currentCredentials();
  const s3 = new S3({credentials: Auth.essentialCredentials(token), signatureVersion: "v2"});
  return s3;
}

async function loadOpds(address: string): Promise<Library> {
  const s3 = await initS3();
  try {
    const opds = await s3.getObject({Bucket: process.env.REACT_APP_AWS_BUCKET as string, Key: `protected/${address}/epub_library.opds`}).promise();
    //const opds = await Storage.get(`protected/${address}/epub_library.opds`, {level: "protected"});
    //const contents = await fetch(opds);
    const blob = opds.Body as Buffer;
    
    //const blob = await (await contents.blob()).text();
    const parser = new XMLParser({
      ignoreAttributes: false,
      attributeNamePrefix: "",
      numberParseOptions: {
        leadingZeros: true,
        hex: false,
        skipLike: /[0-9]{23}/
      },
      isArray: (name): any => {
        if (["author", "contributor"].includes(name)) return true;
      }
    });
    const books = parser.parse(blob);
    const arrayedBooks = Array.isArray(books.feed.entry) ? books.feed.entry : [books.feed.entry];
    localStorage.setItem("books", JSON.stringify(arrayedBooks));
    return { status: "complete", books: arrayedBooks };
  } catch (e: unknown) {
    console.error(e);
    return { status: "empty", books: [] };
  }
}

async function loadBookSettings(filename: string, address: string):Promise<Settings> {
  const s3 = await initS3();
  try {
    //const settings = await Storage.get(filename+'.json', { level: "private"});
    const settings = await s3.getObject({Bucket: process.env.REACT_APP_AWS_BUCKET as string, Key: `protected/${address}/${filename}.json`}).promise();
    //const contents = await fetch(settings);
    //const blob = await (await contents.blob()).text();
    return JSON.parse(settings.Body!.toString());
  } catch (e: unknown) {
    console.error(e);
    //await Storage.put(filename+".json", new File([""], filename+".json"), { level: "private"});

      await s3.putObject({Body: new TextEncoder().encode(JSON.stringify({ location: null, bookmarks: null, annotations: null })), Bucket: process.env.REACT_APP_AWS_BUCKET as string, Key: `protected/${address}/${filename}.json`}).promise();

    return { location: null, bookmarks: null, annotations: null };
  }
}

async function saveBookSettings(filename: string, address: string, settings: Settings) {
  const s3 = await initS3();
  try {
    await s3.putObject({Body: new TextEncoder().encode(JSON.stringify(settings)), Bucket: process.env.REACT_APP_AWS_BUCKET as string, Key: `protected/${address}/${filename}.json`}).promise();
    //await Storage.put(filename+".json", new File([JSON.stringify(settings)], filename+".json"), { level: "private"});
  } catch (e: unknown) {
    console.error(e);
    return e;
  }
}

function bookFromOpds(id: string) {
  const allBooks = JSON.parse(localStorage.getItem("books") as any);
  const book = allBooks.find((item: any) => item.nft.id.toString() === id);
  return book;
}

async function epubHeader(key: string, address: string) {
  const s3 = await initS3();
  const headers = await s3.headObject({Bucket: process.env.REACT_APP_AWS_BUCKET as string, Key: `protected/${address}/${key}`}).promise();
  return headers;
}

export { loadOpds, bookFromOpds, epubHeader, loadBookSettings, saveBookSettings };
