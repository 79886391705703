import { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from "context/AppContext";
import { useFormFields } from "libs/fields";
import { onError } from "libs/error";
import { Auth } from "@aws-amplify/auth";
import { SubmitButton, EmailInput, ClickButton } from "components/FormElements";
import Header from "components/Header";
import Footer from "components/Footer";

export default function Login() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const { userHasAuthenticated, setSession } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(<span></span>);

  const [fields, handleFieldChange] = useFormFields({
    email: ""
  });

  function validateForm() {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(fields.email);
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const session = await Auth.signIn(fields.email);
      setSession!(session);

      if (session.challengeName === "CUSTOM_CHALLENGE") {
        setIsLoading(false);
        navigate(`/login/mfa${location.search}`);
      } else {
        setIsLoading(false);
        userHasAuthenticated!(true);
      }
    } catch (e: any | unknown) {
      if (e.code === "UserNotConfirmedException") {
        navigate("/signup/verification", { state: { username: fields.email } });
      } else if (e.code === "PasswordResetRequiredException") {
        navigate("/login/confirm", { state: { email: fields.email } });
      }
      const errorHandler = onError(e);
      setErrorMessage(errorHandler);
      setIsLoading(false);
    }
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow bg-ct-lightgrey px-4 md:px-0">
        <h2 className="text-3xl text-black text-center pt-20 mb-2.5">{t("local.login.title.enteremail")}</h2>
        <p className="text-base text-black text-center mb-6">{t("local.login.subtitle.info")} </p>
        <form className="container mx-auto max-w-md flex flex-col justify-center space-y-4 pb-20" onSubmit={handleSubmit}>
          <EmailInput handleChange={handleFieldChange} value={fields.email} label={t("global.label.email")} />
          <SubmitButton disabled={!validateForm() || isLoading} loading={isLoading} label={t("global.button.continue")} />
          {errorMessage}
          <div className="flex items-center pb-4">
            <hr className="flex-1 border-t-[1px] border-bookchain-grey" />
            <p className="px-1 uppercase">{t("local.login.label.or")}</p>
            <hr className="flex-1 border-t-[1px] border-bookchain-grey" />
          </div>
          <ClickButton
            handleClick={() => (window.location.href = t("global.link.website.url"))}
            disabled={isLoading}
            loading={isLoading}
            label={t("local.login.link.buytitle")}
          />
        </form>
      </main>
      <Footer />
    </div>
  );
}
