import { useEffect, useRef } from "react";
import ReaderApi from "colibrio/ReaderApi";
import { IContentLocation } from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-base";

export type ColibrioReaderProps = {
  readerApi: ReaderApi;
  settings: Settings;
};

type Settings = {
  location: IContentLocation | null;
  bookmarks: object | null;
  annotations: object | null;
}

const Reader = ({ readerApi, settings }: ColibrioReaderProps) => {
  const viewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resize = () => readerApi.refreshReaderView();
    let viewElement = document.getElementById("reader-view");

    if (viewRef && viewRef.current) {
      readerApi.renderToElement(viewElement, settings.location);
      window.addEventListener("resize", resize);
    }
    return () => {
      //readerApi.renderToElement(viewElement);
      window.removeEventListener("resize", resize);
    };
  }, [viewRef, readerApi]);

  return <div id="reader-view" className={`h-screen w-full max-h-full relative flex flex-col`} ref={viewRef} />;
};

export default Reader;
