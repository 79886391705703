import { IRandomAccessDataSource, MediaType } from "@colibrio/colibrio-reader-framework/colibrio-core-io-base";
import {
  EpubOcfResourceProvider,
  IEpubOcfResourceProvider,
  IEpubPublication
} from "@colibrio/colibrio-reader-framework/colibrio-core-publication-epub";
import {
  EpubContentPositionTimelineOptions,
  EpubFormatAdapter,
  IEpubReaderPublication,
  IEpubReaderPublicationOptions
} from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-epub";
import {
  ContentPositionTimelineUnit,
  IContentLocation,
  IContentPositionTimeline,
  IReaderPublication,
  IReaderView,
  IReadingSessionOptions
} from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-base";
import { ReadingSystemEngine, ResponsiveViewRule } from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-engine";
import { IPdfReaderPublication } from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-pdf";
import { FlipBookRenderer, StackRenderer } from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-renderer";

import APIKEY from "../.apikey";
import HttpDataSource from "./HTTPDataSource";
import platformLogo from "images/logo-platform.png";
import "styles/loader-animation.css";

import { HTTPRandomAccessDataSource } from "colibrio/HTTPRandomAccessDataSource";
import AES256gcmEncryptionMethod from "colibrio/AES256gcmEncryptionMethod";
const FLIPBOOK = "flipbook";
const STACK = "stack";

export interface IReadingProgressData {
  readonly startPosition: number;
  readonly endPosition: number;
  readonly length: number;
}

interface ReaderViewCustomAttribute extends IReaderView {
  backGroundColor?: string;
}

export class ReaderApi {
  public epubPublication: IReaderPublication | null = null;
  private setProgressCallback(value: ((progressData: IReadingProgressData) => void) | null) {
    this._progressCallback = value;
  }
  private setTimeline(value: IContentPositionTimeline | null) {
    this._timeline = value;
  }

  private readingSystem: ReadingSystemEngine;
  public readerView: ReaderViewCustomAttribute;
  private _timeline: IContentPositionTimeline | null = null;
  private _progressCallback: ((progressData: IReadingProgressData) => void) | null = null;
  private selectionTimeOutHandle: number | undefined = undefined;
  private constructor(licenseApiKey: string) {
    //Setup reading system and reading views
    this.readingSystem = new ReadingSystemEngine({
      licenseApiKey: licenseApiKey
    });
    this.readingSystem.addFormatAdapter(new EpubFormatAdapter());
    this.readerView = this.readingSystem.createReaderView();

    this.readerView.setContentOnLoading(`<img class="animation" src=${platformLogo} />`);
    const flipBookRenderer = new FlipBookRenderer({
      name: FLIPBOOK
    });
    const stackRenderer = new StackRenderer({
      name: STACK
    });

    let stackRendererIgnoreAspectRatio = new StackRenderer({
      ignoreAspectRatio: true
    });

    this.readerView.addRenderer(flipBookRenderer, new ResponsiveViewRule("(min-width: 600px)"));
    this.readerView.addRenderer(stackRenderer, new ResponsiveViewRule("(min-width: 450px)"));
    this.readerView.addRenderer(stackRendererIgnoreAspectRatio, new ResponsiveViewRule("(min-width: 300px) and (max-width: 450px)"));

    this.readerView.addEngineEventListener("visibleRangeChanged", () => this.reportProgress(this._progressCallback));
  }

  static loadPublicationByUrl = async (username: string, bookId: string, fileName: string, fileSize: number): Promise<ReaderApi> => {
    const readerApi = new ReaderApi(APIKEY);
    return ReaderApi.loadPublicationFromUrl(readerApi.readingSystem, username, fileName, bookId, fileSize)
      .then((publication) => readerApi.setPublication(publication))
      .then((publication) => ReaderApi.createTimeline(publication))
      .then((timeline) => readerApi.setTimeline(timeline))
      .then(() => readerApi);
  };

  private static async loadPublicationFromUrl(
    readingSystem: ReadingSystemEngine,
    username: string,
    bookId: string,
    fileName: string,
    fileSize: number
  ) {
    const publication = await ReaderApi.getEpubPublicationFromUrl(username, bookId, fileName, fileSize);
    const readerPublicationOptions: IEpubReaderPublicationOptions = {
      enableMediaStreaming: true
    };
    const readingSessionOptions: IReadingSessionOptions = {
      publicationToken: "n/a",
      userToken: "n/a"
    };
    return await readingSystem.loadPublication(publication, readerPublicationOptions, readingSessionOptions);
  }

  static LoadByRandomAccessDataSource = async (httpDataSource: HttpDataSource): Promise<ReaderApi> => {
    const readerApi = new ReaderApi(APIKEY);
    return ReaderApi.loadPublicationFromRandomAccessDataSource(readerApi.readingSystem, httpDataSource)
      .then((publication) => readerApi.setPublication(publication))
      .then((publication) => ReaderApi.createTimeline(publication))
      .then((timeline) => readerApi.setTimeline(timeline))
      .then(() => readerApi);
  };

  renderToElement = (element: HTMLElement | null, position: IContentLocation | null): void => {
    if (element) {
      this.readerView.renderTo(element);
    }
    if (this.readerView.getReaderDocuments().length > 0) {
      //could go to a reading position from a previous session here
      if (position) {
        this.readerView.goTo(position).catch((error) => console.error("Failed to go to previous position: ", error))
      } else {
        this.readerView.goToStart().catch((error) => console.error("Failed to go to start: ", error));
      }
    }
  };

  next = (): void => {
    if (this.readerView.canPerformNext()) {
      this.readerView.next().catch(() => console.error("Failed to navigate next"));
    }
  };

  previous = (): void => {
    if (this.readerView.canPerformPrevious()) {
      this.readerView.previous().catch(() => console.error("Failed to navigate previous"));
    }
  };

  getReadingSystem = (): ReadingSystemEngine => {
    return this.readingSystem;
  };

  setReadingProgressCallback = (callback: (progressData: IReadingProgressData) => void): void => {
    this.setProgressCallback(callback);
  };

  removeReadingProgressCallback = (): void => {
    this.setProgressCallback(null);
  };

  navigateToReadingProgressValue = (progress: number): void => {
    if (this._timeline) {
      this._timeline
        .fetchContentLocation(progress)
        .then((location) =>
          this.readerView.goTo(location, {
            setReadingPositionToVisibleRangeStart: true
          })
        )
        .catch(() => console.warn("Navigation failed"));
    }
  };

  refreshReaderView = () => {
    this.readerView.refresh(false);
  };

  destroy = () => {
    this.readingSystem.destroy();
    this.setTimeline(null);
    this.setProgressCallback(null);
  };

  private reportProgress(callback: ((progressData: IReadingProgressData) => void) | null): void {
    if (this._timeline && callback) {
      const timelineLength = this._timeline.getLength();
      const readingPosition = this.readerView.getVisibleRange();
      if (readingPosition) {
        this._timeline.fetchTimelineRange(readingPosition).then((position) =>
          callback({
            startPosition: position.start,
            endPosition: position.end,
            length: timelineLength
          })
        );
      }
    }
  }

  private static async createTimeline(readerPublication: IReaderPublication): Promise<IContentPositionTimeline> {
    if (this.isEpub(readerPublication) && this.hasTimelineUnit(readerPublication, ContentPositionTimelineUnit.PAGES)) {
      const timelineOptions: EpubContentPositionTimelineOptions = {
        unit: ContentPositionTimelineUnit.PAGES
      };
      const epubPublication = readerPublication as IEpubReaderPublication;

      return epubPublication.createContentPositionTimeline(readerPublication.getSpine(), timelineOptions);
    } else {
      const pdfPublication = readerPublication as IPdfReaderPublication;
      return pdfPublication.createContentPositionTimeline(readerPublication.getSpine());
    }
  }

  private static isEpub(readerPublication: IReaderPublication): boolean {
    return readerPublication.getSourcePublication().getMediaType() === MediaType.APPLICATION_EPUB_ZIP;
  }

  private static hasTimelineUnit(readerPublication: IReaderPublication, unit: ContentPositionTimelineUnit): boolean {
    return readerPublication.getAvailableContentPositionTimelineUnits().includes(unit);
  }

  private static getEpubPublicationFromUrl = async (
    username: string,
    bookId: string,
    fileName: string,
    fileSize: number
  ): Promise<IEpubPublication> => {
    const remoteEpub = new HttpDataSource(username, bookId, fileSize) as IRandomAccessDataSource;

    const ocfResourceProvider = await EpubOcfResourceProvider.createFromRandomAccessDataSource(remoteEpub);

    let drmManager = ocfResourceProvider.getDrmManager();

    drmManager.addEncryptionMethod(new AES256gcmEncryptionMethod(ocfResourceProvider, fileName, username) as any);

    let publication = ocfResourceProvider.getDefaultPublication();

    if (!publication) {
      throw new Error("The file did not contain any EPUB publication");
    }
    return publication;
  };

  private setPublication(publication: IReaderPublication): IReaderPublication {
    this.readerView.setReaderDocuments(publication.getSpine());
    return publication;
  }

  private static async loadPublicationFromRandomAccessDataSource(readingSystem: ReadingSystemEngine, httpDataSource: HttpDataSource) {
    const publication = await ReaderApi.createFromRandomAccessDataSource(httpDataSource);
    const readerPublicationOptions: IEpubReaderPublicationOptions = {
      enableMediaStreaming: true
    };
    const readingSessionOptions: IReadingSessionOptions = {
      publicationToken: "n/a",
      userToken: "n/a"
    };
    return await readingSystem.loadPublication(publication, readerPublicationOptions, readingSessionOptions);
  }

  private static createFromRandomAccessDataSource = async (httpDataSource: HttpDataSource): Promise<IEpubPublication> => {
    const epubOcfResourceProvider = await EpubOcfResourceProvider.createFromRandomAccessDataSource(httpDataSource as IRandomAccessDataSource);
    let publication = epubOcfResourceProvider.getDefaultPublication();
    if (!publication) {
      throw new Error("The file did not contain any EPUB publication");
    }
    return publication;
  };
}

export default ReaderApi;
